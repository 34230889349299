.container {
  color: white;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  gap: 1dvh;
  transition: all ease-out 0.2s;
  font-size: 0.875rem;
}

.textContent {
  padding: 2dvh 1.25dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.textContent .title {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.5dvh;
  display: inline-block;
}

.textContent .subtitle {
  font-size: var(--chakra-fontSizes-xs);
  line-height: 1.05rem;
  font-weight: 400;
  white-space: wrap;
  width: 100%;
}

.subtitle > span {
  font-size: var(--chakra-fontSizes-sm);
  font-weight: 700;
}

.animation {
  animation: scrollText 40s linear 2s infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(var(--scroll-distance));
  }
  50% {
    transform: translateX(var(--scroll-distance));
  }
  75% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
  }
}

.fader {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1rem;
  z-index: 1;
  transition: background ease-out 0.2s;
}

.faderLeft {
  left: 0;
  background: linear-gradient(to right, var(--chakra-colors-darkMode-500), rgba(0, 0, 0, 0));
  transition: background ease-out 0.2s;
}

.faderRight {
  right: 0;
  background: linear-gradient(to left, var(--chakra-colors-darkMode-500), rgba(0, 0, 0, 0));
  transition: background ease-out 0.2s;
}

.rewards {
  padding-right: 1.5dvh;
  line-height: 1rem;
  gap: 0.5dvh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.reward {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container:hover {
  background-color: var(--chakra-colors-luigisPants-400);
  cursor: pointer;
}

.container:hover .faderLeft {
  background: linear-gradient(to right, var(--chakra-colors-luigisPants-400), rgba(0, 0, 0, 0));
}

.container:hover .faderRight {
  background: linear-gradient(to left, var(--chakra-colors-luigisPants-400), rgba(0, 0, 0, 0));
}
